import { PuzzlePieceIcon } from "@heroicons/vue/20/solid";

export const createAreaOptions = [
  {
    name: "Explore the map",
    markdown: `
1. {% inlineRouterLink articleId="map-movement" %}Pan/zoom the map{% /inlineRouterLink %} until you are able to clearly see individual buildings.
2. When you are close enough to the ground, a bright opening will appear at the center of the map.`,
  },
  {
    name: "Visit a property",
    markdown: `
Heading to a property page automatically creates an explored area at the property's location. Here are three common ways to visit a property:

- Select a {% inlineRouterLink articleId="search" %}property search result{% /inlineRouterLink %}
- Select a {% inlineRouterLink articleId="map-markers" %}property, land covering, or parcel marker{% /inlineRouterLink %}
- Click {% inlineAppIcon iconName="rectangleStack" %}**View property diagram**{% /inlineAppIcon %} from the action menu of a {% inlineRouterLink %}datapoint{% /inlineRouterLink %} that links to a property.
    `,
  },
  {
    name: "Link to a contact",
    markdown: `When you {% inlineRouterLink %}link your user account to a contact{% /inlineRouterLink %}, explored areas are created for properties that the contact is involved with.
    `,
  },
];

export const mapExploredAreas = {
  order: 5,
  name: "Explored areas",
  subcategory: "The map",
  icon: PuzzlePieceIcon,
  iconColor: "text-yellow-500",
  markdown: `# Explored areas

  Explored areas geographically personalize your experience in Tower Hunt. You create them in {% inlineRouterLink %}Big Map{% /inlineRouterLink %} and {% inlineRouterLink %}Compact Map{% /inlineRouterLink %} within a property page. Keep reading to learn more about how they work.

  {% callout type="check" %}
  **Important:** No one but you can see your explored areas.
  {% /callout %}

  ## What are explored areas?

  An explored area is a location that you have viewed at a local level. Collectively, your explored areas represent your geographic interests. In the vastness of real estate, explored areas keep the noise at a reasonable level.

  ![Explored area screenshot](https://assets.towerhunt.com/site/Transparent_dark_lines_T0.png)

  ## Create an explored area

  There are three ways to create an explored area: {% inlineRouterLink articleId="map-movement" %}Exploring the map{% /inlineRouterLink %}, visiting a {% inlineRouterLink %}property page{% /inlineRouterLink %}, and being {% inlineRouterLink %}linked to a contact{% /inlineRouterLink %} that is involved with a property.

  {% tabbedContainer tabs=$createAreaOptions /%}

  ## Explored area effects

  As soon as you begin creating explored areas, they change what you are shown in Tower Hunt. Refer to the table below to understand how explored areas affect you.

  | **Activity** | **Effect** |
  |---|---|
  | {% inlineRouterLink articleId="map-layers" %}Map layers{% /inlineRouterLink %} | Explored areas appear as bright openings in a darkness on top of the selected layer |
  | {% inlineRouterLink articleId="map-markers" %}Map markers{% /inlineRouterLink %} | Markers appear within and just beyond the edges of explored areas |
  | {% inlineRouterLink %}News{% /inlineRouterLink %} | Properties within and nearby explored areas can send you news events |
  | {% inlineRouterLink %}Validations{% /inlineRouterLink %} | You are offered validation opportunities for properties within and nearby explored areas |

  {% callout type="note" %}
  **Note:** Explored areas do not limit {% inlineRouterLink articleId="search" %}search results{% /inlineRouterLink %}.
  {% /callout %}`,
};
